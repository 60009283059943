@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.active {
  color: #fffcf7;
}

@media (min-width: 1024px) {
  .active {
    color: #f37748;
  }
}
